.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.canvasjs-chart-credit {
  display: none !important;
}

.canvasjs-chart-toolbar {
  display: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

label {
  font-weight: 500 !important;
}
.chakra-select__icon-wrapper {
  right: 0px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
 .loaderPanel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 9999;
}
.loaderPanel:before {
  content: "";
  background:#2e2e2e38;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.loading-text {
  font-weight: 600;
}
.loader-box {
  text-align: center;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height:72px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #f0ac00;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 72px;
  }
  50%, 100% {
    top: 24px;
    height: 36px;
  }
}

.loading-indicator svg {
  position: relative;
  z-index: 2;
}

.chakra-checkbox__control[data-checked] {
  background: #d69e2e !important;
  border-color: #d69e2e !important;
  color: #ffffff !important;
}

.swal-button {
  background-color: #f0ac00 !important;
  box-shadow: none !important;
}

.swal-button:hover {
  background-color: #c58e00 !important;
  box-shadow: none;
}

.swal-text {
  text-align: center;
  font-size: 14px;
}

.swal-icon--success__ring {
  border: 4px solid #f0ac00 !important;
}

.swal-icon--success__line {
  background-color: #f0ac00 !important;
}

.swal-modal {
  width: 100%;
  max-width: 600px;
}

select:disabled {
  opacity: 1 !important;
}

input:disabled{
  opacity: 1 !important;
}






.navbarBox::-webkit-scrollbar {
  width:12px;
}
.navbarBox::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(71, 71, 71, 0);
}

.navbarBox::-webkit-scrollbar-thumb {
background-color: rgba(181, 181, 181, 0);
outline: 0px solid slategrey;
transform:all ease .5s;

}


.navbarBox:hover::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(71, 71, 71, 0);
}

.navbarBox:hover::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  outline: 0px solid slategrey;
  transform:all ease .5s;
  }


.navbarBox:hover::-webkit-scrollbar {
  width:12px;
}

.navbarBox svg.chakra-icon.chakra-accordion__icon {
  margin-left: auto;
}